import React from "react"
import styled from "styled-components"
import { device } from "../../utils/mediaQueries"
import OperatorImage from "../images/OperatorImage"
import { OutboundLink } from "gatsby-plugin-gtag"
import { createRedirectUrl } from "../../utils/redirectHelpers/modifyUrlHelpers"

const Card = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1rem;
  border-radius: 20px;
  margin: 1rem 10rem;
  background-color: white;
  text-align: center;
  justify-content: center;
  align-items: center;
  grid-gap: 1rem;
  border-bottom: 1px solid black;
  border-radius: 0px;
  @media ${device.tablet} {
    flex-direction: column;
    background-color: white;
    padding: 1rem 0.2rem;
    margin: 1rem 0.2rem;
    border-bottom: 1px solid black;
    border-radius: 0px;
  }
`

const OrderButton = styled.button`
  padding: 1rem 2rem;
  background-color: #a35dbc;
  border: none;
  max-width: 200px;
  border-radius: 20px;
  outline: none;
  color: white;
  font-size: 0.8rem;
  text-align: center;
  font-weight: 600;
  cursor: pointer;
  text-transform: uppercase;
  :hover {
    background-color: violet;
  }
`

const CardItem = styled.div`
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  margin: 1rem;
  align-items: center;
  text-align: center;
  @media ${device.tablet} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0.2rem 1rem;
  }
`
const PlanItem = styled.div`
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  width: 200px;
`

const BoldFont = styled.div`
  font-size: 24px;
  font-weight: 800;
  text-transform: lowercase;
  margin: 0rem auto;
`

const PlusFont = styled.div`
  font-size: 18px;
  font-weight: 800;
  text-transform: uppercase;
  padding: 1px;
  margin: 1px;
`

const LightFont = styled.div`
  font-size: 15px;
  font-weight: 300;
  color: #565656;
  text-transform: uppercase;
`

const OpenFeeText = styled.div`
  font-size: 13px;
  font-weight: 300;
  color: #565656;
  text-transform: uppercase;
`

const PriceTag = styled.span`
  font-size: 14px;
  text-transform: lowercase;
  font-weight: 200;
`

const PriceText = styled.div`
  font-size: 20px;
  font-weight: 900;
  text-transform: uppercase;
  margin: 0rem auto;
`

const PlanName = styled.div`
  margin: 0px 0px;
  font-size: 0.8rem;
  font-weight: 700;
  word-wrap: break-word;
`

const List = styled.div`
  display: block;
  color: #9a3cbb;
  font-size: 0.8rem;
  font-weight: 800;
  margin: 0px auto;
  padding: 0rem 1rem;
  @media ${device.tablet} {
    padding-bottom: 2rem;
    padding-top: 1rem;
  }
`
const ListITem = styled.p`
  margin: 0px 0px 0px 0px;
  padding: 0.2rem;
  word-wrap: break-word;
  width: 300px;
`

const InternetPlanCard = props => {
  return (
    <Card>
      <PlanItem>
        <OperatorImage imgName={props.img} />
        <PlanName>{props.name}</PlanName>
        <OpenFeeText>Aloitusmaksu {props.openFee} €</OpenFeeText>
      </PlanItem>
      <CardItem>
        <BoldFont>
          {props.price}€ <PriceTag>/kk</PriceTag>
        </BoldFont>
      </CardItem>
      <CardItem>
        <PriceText>
          {props.internetSpeed} <PriceTag> Mbit/s</PriceTag>
        </PriceText>
      </CardItem>
      <div>
        <List>
          {props.benefits.map(benefit => (
            <ListITem key={benefit}>{benefit}</ListITem>
          ))}
        </List>
      </div>
      <OutboundLink
        href={createRedirectUrl(props.pageName, props.name)}
        target="blank"
        rel="nofollow"
      >
        <OrderButton>Tutustu ja tilaa</OrderButton>
      </OutboundLink>
    </Card>
  )
}

export default InternetPlanCard
