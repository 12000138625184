import React from "react"
import Container from "../components/layout/textFormatingComponents/Container"
import { graphql } from "gatsby"
import InternetPlanCard from "../components/resultComponents/InternetPlanCard"
import GlobalStyles from "../components/layout/GlobalStyles"
import Header1 from "../components/layout/textFormatingComponents/Header1"
import Header2 from "../components/layout/textFormatingComponents/Header2"
import Header3 from "../components/layout/textFormatingComponents/Header3"
import Header4 from "../components/layout/textFormatingComponents/Header4"
import TextArea from "../components/layout/textFormatingComponents/TextArea"
import MetaDetails from "../components/seoComponents/MetaDetails"
import RowContainer from "../components/layout/textFormatingComponents/RowContainer"
import Button from "../components/layout/Button"
import { OutboundLink } from "gatsby-plugin-gtag"

const FrontPage = ({ data }) => {
  const plansHook = data.allInternetPlansJson.nodes
  const plans = plansHook.map(plan => {
    return (
      <InternetPlanCard
        key={plan.id}
        img={plan.img}
        name={plan.name}
        price={plan.price}
        internetSpeed={plan.internetSpeed}
        url={plan.url}
        openFee={plan.openFee}
        benefits={plan.benefits}
        pageName={"mobiililaajakaista"}
      />
    )
  })

  return (
    <GlobalStyles>
      <MetaDetails
        title="Paras ja halvin mobiililaajakaista 2020 - Nettiliittymä vertailu"
        desc="Vertaa paras ja halvin mobiililaajakaista täältä. Nettiliittymien vertailulla voit säästä jopa kymmeniä euroja kuukaudessa. Katso paras tarjous!"
      />
      <Container>
        <Header1>Paras ja halvin mobiililaajakaista kenelle tahansa</Header1>
        <TextArea>
          Mobiililaajakaista tuo internetin laitteelle kuin laitteelle. Valintaa
          riittää kuitenkin useaan eri tarpeeseen ja nopeuteen.{" "}
          <b>
            Alapuolelta löydät tämän hetken tarjonnan mobiililaajakaistoihin
          </b>
          . Valitse niistä sinulle sopivin tarjous.
        </TextArea>
        <Header3>
          Tutustu mobiililaajakaistoihin alapuolelta ja nappaa paras tarjous:
        </Header3>
        {plans}
        <TextArea>
          Jos tarvitset myös mokkulaa,{" "}
          <a href="/mokkula">niin katso täältä parhaat mokkulat</a>
        </TextArea>
        <RowContainer title={"Tutustu myös seuraaviin vertailuihin:"}>
          <Button link={"/lapselle/"} title={"Puhelinliittymä lapselle"} />
          <Button
            link={"/puhelinliittymavertailu/"}
            title={"Puhelinliittymävertailu"}
          />
          <Button
            link={"/ilman-nettia/"}
            title={"Puhelinliittymä ilman nettiä"}
          />
          <Button link={"/prepaid/"} title={"Prepaid"} />
        </RowContainer>
        <Header2>Mikä on halvin mobiililaajakaista?</Header2>
        <TextArea>
          Internet on nykyään tärkeä ja jopa itsestään selvä osa ihmisten koulu-
          ja työelämää sekä vapaa-aikaa. Internetiä käytetään usein
          mobiililaajakaistan avulla. Mobiililaajakaista on useille ihmisille
          internetiin liittyvä termi, mutta kaikki eivät tiedä mitä se tarkkaan
          ottaen tarkoittaa, kuten millaisia erilaisia nettiyhteyksiä se tarjoaa
          tai miten mobiililaajakaista itse asiassa toimii.
        </TextArea>
        <TextArea>
          Mobiililaajakaista on yksinkertaisesti sanottuna SIM-kortin kautta
          toimiva liikkuva laajakaista, jonka käyttö ei ole sidottu vain ja
          ainoastaan yhteen paikkaan. Mobiililaajakaistaa voidaan käyttää niin
          mobiililaitteilla, kuten puhelimilla ja tableteilla, kuin myös
          tietokoneillakin. Mobiililaajakaista on erittäin yleinen tapa käyttää
          internetiä eri laitteilla, ja se onkin käytettävissä lähes kaikkialla
          Suomen alueella.
        </TextArea>
        <Header3>Mobiililaajakaistan nopeudet</Header3>
        <TextArea>
          Mobiililaajakaista toimii pelkästään matkapuhelinverkossa, jonka takia
          se vaatii toimiakseen matkapuhelinverkon signaalin.
          Mobiililaajakaistan nopeudet vaihtelevat eri syistä johtuen. Nopeudet
          vaihtelevat muun muassa oman mobiililaajakaistasopimuksen, oman
          laitteen sekä käytössä olevan verkkotekniikan, kuten myös sijainnin,
          kuuluvuuden voimakkuuden, verkon kuormituksen ja häiriötasojen mukaan.
        </TextArea>
        <Header4>Mobiililaajakaista kuuluvuus</Header4>
        <TextArea>
          Esimerkiksi kuuluvuusalueiden paikalliset katveet voivat vaikuttaa
          yhteyteen. Katvealueita voivat olla esimerkiksi oman talon rakenteet
          tai viereiset talot. Oman kuuluvuusalueensa voi kuitenkin halutessaan
          tarkastaa erilaisista kuuluvuuskartoista. Esimerkiksi Elisan
          sivustolla on kartta, josta voi tarkastaa oman paikkakuntansa ja jopa
          osoitteensa kuuluvuuden verkon nopeuden mukaan. Karttaa on hyvä
          hyödyntää kuuluvuuden ja nopeuden tarkistamiseen jo ennen uuden
          liittymän hankkimista.
        </TextArea>
        <Header3>Erilaiset mobiiliverkot</Header3>
        <Header4>2G verkko</Header4>
        <TextArea>
          Erittäin kevyeen käyttöön tarkoitetun 2G-verkon, toisin sanoen
          GSM-verkon netin nopeus voi olla korkeimmillaan noin 100-150 kbit/s.
          Se tarkoittaa, sitä että tällaisella netti on hyvä käyttää vaikkapa
          esimerkiksi sähköpostin lähettämiseen ja lukemiseen, mutta ei
          esimerkiksi nettipelaamiseen.
        </TextArea>
        <Header4>3G verkko</Header4>
        <TextArea>
          3G-verkko on 2G-verkkoa hieman nopeampi ja sen nopeudet voivat
          vaihdella välillä 0,4-25 Mbit/s. Tällaisella nopeudella onnistuu netin
          perusselaaminen, mutta sujuvaan pelaamiseen ja videoiden katselemiseen
          on parempi hankkia nopeampi nettiyhteys. Mikäli puhelinta käyttää
          enimmäkseen vain puheluita varten, 3G-verkolla pärjää hyvin, jolloin
          nopeamman 4G-verkon lisänopeudesta ei luultavasti ole silloin
          oikeastaan hyötyä. Mikäli sen sijaan käyttää internetiä aktiivisesti
          tai pelaa verkkopelejä, nopeampi 4G-yhteys on parempi vaihtoehto.
        </TextArea>
        <Header4>4G verkko</Header4>
        <TextArea>
          4G on niin kutsutun neljännen sukupolven langatonta
          tiedonsiirtoteknologiaa. Se on uusinta tekniikkaa ja mahdollistaa
          erittäin nopean surffailun internetissä. 4G-verkko sopiikin kaikista
          aktiivisimmille internetin käyttäjille, toisin kuin sitä hitaammat 2G-
          ja 3G-verkot, jotka sopivat paremmin internetin kevyempään
          käyttämiseen. 4G-verkon nopeudet vaihtelevat välillä 5-240 Mbit/s.
          Nopeita 4G-verkkoja saa tänä päivänä kohtuullisen edulliseen
          kuukausihintaan, ja niitä voi ostaa myös etukäteen ladattavina
          prepaid-liittyminä. Hitaammat 2G- ja 3G-verkot ovat edelleen
          toiminnassa, mutta niitä käyttävät vain vanhemmat matkapuhelimet ja
          katvealueet. Nopeampi mobiililaajakaista maksaa yleensä aina hitaampaa
          enemmän, mutta hinnat vaihtelevat tietenkin myös operaattorin mukaan.
          Mutta mistä sitten tietää millaisen ja kuinka nopean liittymän
          tarvitsee?
          <ul>
            <li>
              2G sopii satunnaiseen ja kevyeen käyttöön vanhemmille
              älypuhelimilla.
            </li>
            <li>
              3G on sopiva internetin peruskäyttöön, joka ei kuitenkaan ole
              aktiivisimmasta päästä.
            </li>
            <li>
              4G sopii jopa aktiivisimmille käyttäjille, jotka tarvitsevat
              erittäin nopean netin.
            </li>
            <li>
              5G sopii esimerkiksi todella suurten tiedostojen lähetykseen,
              huippunopeaan selaamiseen ja kaikista luotettavimmaksi
              nettiliittymäksi listalla mainituista.
            </li>
          </ul>
        </TextArea>
        <Header4>5G</Header4>
        <TextArea>
          Huippunopean 4G-verkon lisäksi mobiilikäyttäjille onkin tulossa vielä
          sitäkin monta kertaa nopeampi 5G-verkko. 5G on niin kutsuttua
          viidennen sukupolven datayhteyttä mobiilitekniikassa. Sen
          tiedonsiirtonopeus ja kapasiteetti ovat noin kymmenkertaisia 4G:hen
          verrattuna. Se tarkoittaa, että suuret tiedostot, kuten elokuvat, ovat
          käyttäjän käytössä todella nopeasti. Lisäksi sen viive on minimaalinen
          ja luotettavuus aiempia verkkoja parempi. Tällä hetkellä 5G-verkkoja
          rakennetaan, ja 5G-verkkoja on avattu jo usealle Suomen
          paikkakunnalle. Ensimmäiset 5G-verkkossa toimivat laitteet ilmestyvät
          vuonna 2020, mutta niitä tulee yleisimmin saataville vuonna 2020. 5G
          mahdollistaa muun muassa sen, että tulevaisuudessa on mukavampi
          katsella erittäin korkeatasoista tv-ohjelmaa 5G-verkossa.
        </TextArea>
        <Header4>Kuuluvuuskartat</Header4>
        <TextArea>
          Voit tarkistaa operaattorikohtaiset kuuluvuuskartat kullekin verkolle
          alapuolelta:
          <OutboundLink
            href="https://www.telia.fi/asiakastuki/verkko/verkko/verkkokartta"
            rel="nofollow"
          >
            Telia
          </OutboundLink>
          <OutboundLink href="https://elisa.fi/kuuluvuus/" rel="nofollow">
            Elisa
          </OutboundLink>
          <OutboundLink
            href="https://www.dna.fi/kuuluvuus-ja-peittoalueet"
            rel="nofollow"
          >
            DNA
          </OutboundLink>
          <OutboundLink href="https://www.moi.fi/kuuluvuusalue" rel="nofollow">
            Moi
          </OutboundLink>
        </TextArea>
        <Header3>DNA-mobiililaajakaista</Header3>
        <TextArea>
          DNA:n nettiliittymät ovat monestakin syystä hyvä valinta
          mobiililaajakaistaliittymäksi. DNA:n netti on esimerkiksi saavuttanut
          jo yli vuoden ajan Netflixin kuukausittaisten mittausten perusteella
          korkeimman keskinopeuden Netflixin ohjelmien katseluun. Netflix ISP
          Speed Index -nimisellä mittarilla mitataan tietyn yhteyden tarjoajan
          nopeutta katsottaessa Netflixiä parhaimpaan katseluaikaan. Palvelulla
          ei kuitenkaan mitata muiden palvelujen tai muun tiedonsiirron nopeutta
          kyseisen palveluntarjoajan verkossa. Netflix ISP Speed Index -mittarin
          Suomen tulokset voi katsoa halutessaan osoitteesta
          <OutboundLink href="http://ispspeedindex.netflix.com/country/finland">
            ispspeedindex.netflix.com/country/finland
          </OutboundLink>
          . Mittarilla on vertailtu tällä hetkellä DNA:n, Elisan ja TeliaSoneran
          nopeuksia. Samaiselta mittaussivustolta voi halutessaan vertailla myös
          eri maiden nettien nopeuksia Netflixin ohjelmien katselussa.
        </TextArea>
        <TextArea>
          DNA:n netin nopeus kilpailijoihin nähden on todistettu tutkimuksessa.
          Omnitelen toukokuussa vuonna 2020 tehdyssä tiedonsiirtonopeuksien
          vertailussa DNA nimittäin ohitti kilpailijansa Elisan ja Telian.
          Omnitelen tutkimuksessa selvitettiin kuluttaja-asiakkaille myytävien
          mobiililiittymien tiedonsiirtonopeuksia. DNA:n nopeudeksi saatiin
          83,9Mbit/s kun Elisan netin nopeus oli 79,6Mbit/s ja Telian netin
          nopeus 63,2 Mbit/s. Tulokset perustuvat mittausten keskimääräisen
          tukiasemalta päätelaitteeseen päin saapuvan liikenteen
          tiedonsiirtonopeuksiin. Tutkimuksissa tutkittiin kunkin operaattorin
          nopeimmat kuluttajille tarkoitetut liittymät. Operaattoreiden
          paremmuusjärjestys ja tutkimusten tulokset kuitenkin myös vaihtelivat
          jonkin verran kaupungin mukaan. Mittauksissa oli mukana väkiluvultaan
          20 suurinta kaupunkia sekä neljä lomakeskusaluetta Pohjois-Suomessa ja
          tiestöt kaupunkien ja lomakeskuksien välillä. Voit testata oman
          nettisi nopeuden sivustomme{" "}
          <a href="/nopeustesti/">internetin nopeustestissä</a>.
        </TextArea>
        <TextArea>
          DNA:lla on tarjolla useita erilaisilla ominaisuuksilla varustettuja ja
          eri hintaisia mobiililaajakaistapaketteja. Erilaisia paketteja voi
          vertaille DNA-sivustolla. DNA tarjoaa myös eri tarkoituksiin olevia
          liittymäpaketteja, kuten esimerkiksi matkapaketteja, jotka sisältävät
          EU-datapaketin. Esimerkiksi{" "}
          <OutboundLink href="/tilaa/dnamatkanetti4gsuper">
            DNA Matkanetti 4G Super
          </OutboundLink>{" "}
          on huippunopea nettiliittymä, joka sisältää EU-datapaketin ja sen
          maksiminopeus on 300 Mbit/s. Halvin mobiililaajakaista on
          <OutboundLink href="/tilaa/dnasupernetti4g">
            matkaliittymä
          </OutboundLink>
          , jonk hinta on 12 kuukauden ajan 29,90 euroa kuukaudessa ja sen
          jälkeen 34,90 euroa kuukaudessa. Operaattorilla on myös toinen
          matkaliittymä:{" "}
          <OutboundLink href="/tilaa/dnamatkanetti4gsuper">
            DNA Matkanetti 4G Max
          </OutboundLink>
          , jonka maksiminopeus on 4G-verkon maksiminopeus. Kyseinen liittymä
          tarjoaa suurimman mahdollisen 4G-tiedonsiirtonopeuden, mikä näkyy myös
          sen hinnassa, sillä sen hinta on 44,90 euroa kuukaudessa.
        </TextArea>
        <TextArea>
          Mobiililaitteilla sopivaksi liittymäksi DNA tarjoaa lisäksi myös
          esimerkiksi{" "}
          <OutboundLink href="/tilaa/dnasupernetti4g">
            DNA Supernetti
          </OutboundLink>
          4G-liittymän, joka on erittäin nopea netti mokkulaan, tablettiin tai
          kannettavaan tietokoneeseen ja sen maksiminopeus on 150 Mbit/s.
          Liittymän hinta on 24,90 euroa kuukaudessa. Lisäksi DNA-operaattoria
          tarjoaa huomattavasti edullisempia liittymiä satunnaiseen käyttöön,
          kuten netin valvontalaitteisiin ja älylaitteiden etäohjausta varten,
          sekä myös prepaid-liittymän, jota ladataan vain tarpeen mukaan ilman
          kuukausittaisia laskuja. Prepaid-liittymän maksiminopeus on 300
          Mbit/s. DNA:n kuukausihinnat ovat suhteellisen edulliset, mutta DNA
          tarjoaa myös erilaisia etuja ja tarjouksia sekä sen uusille että
          vanhoille asiakkaille. Lisäksi DNA tarjoaa asiakkaille 30 päivän
          tyytyväisyystakuun.
        </TextArea>
        <Header3>Telia-mobiililaajakaista</Header3>
        <TextArea>
          DNA:n tapaan myös Telia tarjoaa edullisia ja nopeita
          mobiililaajakaistaliittymiä asiakkaille. Telia tarjoaa sekä nopeita
          peruskäyttöön soveltuvia mobiilinettejä kuin myös vaativaan ja
          aktiiviseen käyttöön tarkoitettuja huippunopeita mobiilinettejä.
          Operaattori tarjoaa nettiliittymiä jopa 1 000 Mbit/s:n nopeuteen
          saakka. Telialla on tarjolla erilaisia liittymiä, joiden ominaisuudet
          sopivat eri tarpeisiin. Se tarjoaa liittymiä niin pelkästään Suomessa
          verkkoa käyttävien käyttöön kuin myös ulkomaanmatkoihin sopivia
          liittymätarjouksia. Liittymiin voi halutessaan tilata erilaisia
          maksullisia lisäpalveluita ja esimerkiksi vanhan puhelinnumeronsa voi
          halutessaan säilyttää vaihtaessaan Telian liittymään. Telialla on myös
          kätevä "Minun Telia" -palvelu, jolla asiakas voi hoitaa
          liittymäasioitaan netin kautta.
        </TextArea>
        <TextArea>
          Telia tarjoaa useita mobiililaajakaistaliittymiä, joista esimerkiksi
          <OutboundLink href="/tilaa/teliarajatonlite100m">
            Rajaton Lite 100 M
          </OutboundLink>{" "}
          tarjoaa rajattoman ja huippunopean netin 24,90 euron kuukausihinnalla.
          4G-verkon maksiminopeus liittymässä on 100Mbit/s. Liittymään kuuluvat
          myös puhelut ja tekstiviestit, jotka maksavat 0,07 euroa per minuutti
          tai kappale. Vielä tätäkin nopeampi netti on{" "}
          <OutboundLink href="/tilaa/teliarajaton450m">
            Rajaton 450 M
          </OutboundLink>
          , jonka hinta on kalliimpi; 39,90 euroa kuukaudessa. Liittymän
          4G-verkon maksiminopeus on 450Mbit/s. Liittymän puheluiden ja
          tekstiviestin määrä on myös rajaton. Netti EU/ETA-maissa on 20Gt per
          kuukausi. Tehokkaiden pakettien lisäksi Telia tarjoaa edullisen
          liittymän kevyeen käyttöön. Halvin mobiililaajakaista{" "}
          <OutboundLink href="/tilaa/teliarajatonlite02">
            Rajaton Lite 0,2 M
          </OutboundLink>{" "}
          maksaa 7,90 euroa kuukaudessa ja siinä on 0,256 Mbit/s -maksiminopeus.
          Tämä tarjous ei kuitenkaa ole kovin hyvä, jos liittymä haluaa käyttää
          johonkin muuhunkin kuin esimerkiksi vain riistakameraan.
        </TextArea>
        <TextArea>
          Perinteisten kuukausimaksulla toimivien liittymien lisäksi Telia
          tarjoaa myös prepaid-mobiililiittymiä. Prepaid-liittymät maksetaan
          etukäteen tarpeen mukaan ja niistä ei siis tule laskuja. Telialla on
          useita prepaid-vaihtoehtoja. Esimerkiksi rajaton 31 päivän
          prepaid-nettipaketti sisältää 100Mbit/s maksiminopeuden. Liittymä
          ladataan kuukaudeksi kerrallaan ja se maksaa 17,90 euroa per kuukausi.
          Telia tarjoaa muitakin prepaid-liittymäpaketteja, joihin liittyy
          lisäksi usein tarjouksia. Mikäli liittymän valitseminen tuottaa
          hankaluuksia, Telia tarjoaa myös apua sivustollaan liittymän valintaan
          liittyen. Esimerkiksi Telian kuuluvuuskartasta voi tarkastaa oman
          kotialueensa tai vaikkapa kesämökkinsä kuuluvuus ja
          tiedonsiirtoyhteyksien nopeus. Lisäksi sivustolla on kätevä
          nopeustesti, jolla voi testata millainen nettiyhteys on mahdollista
          saada kotiinsa.
        </TextArea>
        <TextArea>
          Telia tarjoaa myös monia mielenkiintoisia tarjouksia asiakkailleen.
          Esimerkiksi alle 28-vuotiaille tarjotaan edullista liittymää 19,90 -
          23,90 euron kuukausihintaan. Paketti sisältää netin rajattoman käytön
          kotimaassa, mutta liittymät sisältävät myös netin käyttöä ulkomailla,
          joten liittymä sopii otettavaksi myös matkalle mukaan. Nuorille on
          tarjolla yhtä lailla monipuolinen peruspaketti hintaan 19,90 euroa
          kuukaudessa kuin myös tehokkaampi ja supernopea liittymä
          aktiivisempaan käyttöön, jonka hinta on hieman perusliittymää
          kalliimpi: 23,90 euroa kuukaudessa. Telialla on myös monia muita
          vaihtuvia etuja. Esimerkiksi luurinmetsästyspäivien monet tarjoukset
          ja mobiililaajakaistatarjous Telian puhelinliittymän tai kiinteä
          laajakaistan haltijoille. Prepaid-liittymän ostajille Telia taas
          tarjoaa ensilataajan etuna viisi euroa saldoa.
        </TextArea>
        <Header3>Elisa-mobiililaajakaista</Header3>
        <TextArea>
          Elisan liittymät ovat varmasti monille tuttuja operaattorin
          televisiokampanjan vuoksi. Elisa tarjoaakin Telian ja DNA:n tavoin
          monia erilaisia mobiililaajakaistapaketteja eri tarkoituksiin. Elisan
          Saunalahti-mobiililaajakaistaliittymät saa aina ilman
          sitoutumispakkoa, datakattoa tai kytkykauppaa. Elisan
          mobiililaajakaistaliittymistä suosituin on Saunalahti
          <b>
            <OutboundLink href="/tilaa/saunalahti4gsuper">
              Mobiililaajakaista 4G Super
            </OutboundLink>
          </b>
          , jonka huippunopeus on 100 Mbit/s ja se sisältää rajattoman netin
          hintaan 18,40 euroa kuukaudessa ensimmäisten 12 kuukauden ajalta.
          Tämän jälkeen hinnaksi muuttuu 29,80 euroa kuukaudessa. Ei olekaan
          mikään ihme, että juuri tämä liittymä on mobiililaajakaistaliittymistä
          menestynein, sillä se tarjoaa kattavan netin melko edullisella
          kuukausihinnalla. Lisäksi liittymä kerryttää S-ryhmän bonusta, mikäli
          asiakas yhdistää bonuskorttinsa tiliinsä.
        </TextArea>
        <TextArea>
          Elisalla on myös kevyeen käyttöön sopivia liittymiä. Nopeudeltaan
          0,128 Mbit/s oleva{" "}
          <b>
            <OutboundLink href="/tilaa/saunalahtimini">
              Saunalahti Mobiililaajakaista Mini
            </OutboundLink>
          </b>{" "}
          sopii laitteiden ohjaukseen, kuten riistakameraan. Se sisältää
          rajoittamattoman tiedonsiirron kotimaassa sekä puhelut hintaan 0,14
          euroa per minuutti. Kuukausihintaan sisältyy 100 tekstiviestiä.
          Liittymä on hyvin edullinen, sillä sen hinta on 3,90 euroa kuussa.
          Elisa tarjoaa myös satunnaiseen netin käyttöön edullisen 2,90 euroa
          kuussa ja 0,99 euroa vuorokaudessa maksavan liittymän, jolla ei
          kuitenkaan voi soittaa. Tehokas mobiilinettiliittymä sen sijaan on
          Saunalahti Mobiililaajakaista Premium +, jonka netin nopeus on 300
          Mbit/s ja joka maksaa 29,90 euroa kuukaudessa ensimmäisen 12 kuukauden
          ajalta. Vielä tätäkin nopeampia ovat uudet 5G-liittymät, jotka:
          <ul>
            <li>
              Tarjoavat mahdollisuuden viiveettömään pelailuun ja
              videonkatseluun
            </li>
            <li>
              Mahdollistavat datansiirron kymmenen kertaa nopeammin kuin
              4G-verkossa
            </li>
            <li>
              Elisan 5G-verkot toimivat jo Helsingissä, Tampereella,
              Jyväskylässä ja Turussa.
            </li>
            <li>
              Ensimmäiset Elisan 5G-laitteet, kuten reitittimet, päätelaitteet
              ja puhelimet ilmestyvät vuoden 2020 aikana.
            </li>
          </ul>
        </TextArea>
        <TextArea>
          Elisalla onkin jo myynnissä paljon puhuttuja 5G-liittymiä.{" "}
          <OutboundLink href="/tilaa/saunalahti5g600">
            Saunalahti Mobiililaajakaista 5G 600M -liittymän
          </OutboundLink>{" "}
          maksiminopeus on 600 Mbit/s, ja liittymä sisältää rajattoman käytön
          Pohjoismaissa ja Baltiassa. Lisäksi 20 Gt per kuukausi -nettipaketti
          EU/ETA-maissa sisältyy kuukausihintaan. Liittymällä ei voi soittaa,
          mutta tekstiviestejä sen sijaan voi lähettää. Myös tästä Elisan
          liittymästä voi kerryttää itselleen S-bonusta. Liittymän hinta on
          34,90 euroa kuukaudessa ensimmäisen vuoden ajan. Toinen Elisan
          5G-mobiililaajakaistaliittymä on{" "}
          <OutboundLink href="/tilaa/saunalahti5g1000">
            Saunalahti Mobiililaajakaista 5G 1 000M
          </OutboundLink>
          , jonka maksiminopeus on huimat 1 000 Mbit/s. Liittymä sisältää toisen
          5G-liittymän tapaan saman EU- ja ETA-alueiden nettipaketin ja sillä ei
          myöskään voi soittaa. Sen hinta on 44,90 euroa kuukaudessa.
        </TextArea>
        <TextArea>
          Elisalla on tarjolla myös prepaid-mobiililaajakaistaliittymiä ilman
          datakattoa. Prepaid-liittymiin ladataan saldoa netin päiväkäyttöä
          varten ostamalla kiinteähintaisen nettipaketin. Elisalla on tarjolla
          eri hintaisia ja ominaisuuksiltaan erilaisia prepaid-paketteja, joita
          voi päivittää omaan liittymäänsä Elisan Prepaid-kaupasta. Suosituin
          mobiililaajakaistapaketti on Rajoitukseton 4G, joka sisältää
          rajattoman netin ja jonka maksiminopeus on 100Mbit/s. Liittymään
          kuuluvat puhelut ja tekstiviestit, joiden maksimirajat ovat viesteillä
          165 viestiä vuorokaudessa ja 5 000 viestiä kuukaudessa. Puheluilla
          raja on 165 minuuttia vuorokaudessa ja 5 000 minuuttia kuukaudessa.
          Paketin hinta on 29,90 euroa kuukaudessa. Tarjolla on myös
          edullisempia, pelkkiä nettipaketteja, ilman puheluita ja
          tekstiviestejä. Tarjonnasta löytyy paketteja esimerkiksi viikoksi ja
          kuukaudeksi.
        </TextArea>
        <Header3>Miten mobiililaajakaistaa käytetään?</Header3>
        <TextArea>
          Mobiililaajakaista on{" "}
          <a href="/blogi/sim-korttien-koot/">SIM-kortin</a> kautta toimiva
          internet-yhteys, joka toimii niin puhelimella, tabletilla kuin
          tietokoneellakin. Puhelimella käytettäessä netti on osa
          puheliittymäsopimusta, mutta tabletilla sitä voi käyttää
          WLAN-yhteydellä tai sen sijasta sellaisella
          mobiililaajakaistaliittymällä, joka on hankittu tabletin käyttöä
          varten. Mobiililaajakaista ei ole ainoastaan mobiililaitteita varten
          sillä sen käyttäminen onnistuu myös tietokoneella. Käyttäminen
          tietokoneella onnistuu reitittimen tai mokkulan avulla. Tosin
          tietokoneen voi myös yhdistää tabletin tapaan WLAN-verkkoon yhteyden
          luomiseksi. WLAN-verkko, reititin ja nettitikku ovatkin monelle hyvin
          tuttuja sanoja, mutta tiedätkö mitä ne itse asiassa ovat ja miten
          niiden avulla voidaan yhdistää eri laitteet
          mobiililaajakaistaverkkoon?
        </TextArea>
        <TextArea>
          WLAN-verkosta käytetään myös nimeä Wi-Fi, joka saattaa olla monelle
          paljon tutumpi nimi. Toisin sanoen WLAN on langaton verkko.
          Yhdistääkseen laitteen verkkoon on tiedettävä langattoman verkon nimi
          ja salasana. Nimi ja salasana löytyvät reitittimen tai modeemin
          pohjassa olevasta tarrasta tai laitteen käyttöoppaasta. Langattomaan
          verkkoon yhdistääkseen on etsittävä verkon nimi laitteestaan,
          painettava "yhdistä"-painiketta ja kirjoitettava tarvittaessa toisesta
          laitteesta katsottu salasana, jotta yhteys muodostetaan. Tämän jälkeen
          laite yhdistää langattomaan lähiverkkoon. Langattoman verkon kuuluvuus
          on sisätiloissa noin 30-50 metriä, mutta muutamat asiat saattavat
          huonontaa kuuluvuutta. Näitä ovat muun muassa huonekalut ja talon
          rakenteet.
        </TextArea>
        <TextArea>
          Entä mikä on reititin? Reititin on yksinkertaisesti laite, joka jakaa
          nettisignaalin kodin verkkolaitteiden kuten esimerkiksi tietokoneiden
          ja kännyköiden kesken. Reitittimen avulla voi siis surffata netissä
          kaikilla laitteillaan. Reitittimiä on erilaisia ja sellaista ostaessa
          kannattaakin miettiä ainakin sitä, kuinka monta laitetta reitittimeen
          on tarkoitus liittää, miten suuri signaalialueen tulisi olla ja kuinka
          nopea yhteyden on oltava. Esimerkiksi pelaamista varten tarvitaan
          nopea yhteys. Langattomien reitittimien hinnoissa ja ominaisuuksissa
          on myös melko suuriakin eroja. Siksi on aina järkevää vertailla eri
          vaihtoehtoja ennen reitittimen ostamista ja tarvittaessa kysyä
          ammattimaista apua valintaan esimerkiksi myymälästä.
        </TextArea>
        <TextArea>
          <a href="/mokkula/">
            Nettitikku tai toisin sanoen USB-modeemi tai mokkula
          </a>{" "}
          sen sijaan on langattomaan matkapuhelinverkkoon kytkeytyvä "tikku",
          johon asetetaan liittymän SIM-kortti ja jonka voi sen USB-pään kautta
          kytkeä tietokoneeseen tai vaikkapa televisioon. Nettitikulla voi
          toisin sanoen jakaa SIM-kortilla toimivan mobiililaajakaistaverkon
          laitteelle. Nettitikkuja myyvät esimerkiksi puhelinoperaattorit, kuten
          DNA ja elektroniikkalaitemyymälät. Nettitikuilla saa hyvän 3G- tai
          4G-yhteyden erityisellä lisäantennilla, jos asuu esimerkiksi
          kuuluvuusalueen reunalla tai muualla, jossa kuuluvuus on huono. Myös
          nettitikkuja ja niiden lisäantenneja on paljon erilaisia ja eri
          hintaisia, joten niiden ominaisuuksiin ja sopivuuteen omiin
          laitteisiinsa kannattaa tutustua hyvin jo ennen tuotteen ostamista.
        </TextArea>
        <Header2>Yhteenveto</Header2>
        <TextArea>
          Mobiililaajakaista on SIM-kortin kautta toimiva verkkoyhteys, jolla
          voi käyttää nettiä missä tahansa laitteessa, joko SIM-kortin suoraan
          itse laitteeseen asettamalla tai apuvälineiden kuten reitittimen
          avulla. Mobiililaajakaistan ominaisuuksissa kuten nopeuksissa on
          eroja. Jokaisen kannattaakin valita ominaisuuksiltaan ja nopeudeltaan
          juuri omiin tarpeisiinsa sopiva mobiililaajakaistaliittymä.
          Mobiililiittymien rajoituksissa on eroja myös esimerkiksi ulkomailla
          käyttämisen suhteen.
        </TextArea>
        <TextArea>
          DNA, Telia ja Elisa tarjoavat useita eri hintaisia ja eri tarpeisiin
          sopivia mobiililaajakaistaliittymiä, joihin kuuluu sekä jälkikäteen
          kuukausittain laskutettavia liittymiä että etukäteen maksettavia
          prepaid-liittymiä. Nopeammat liittyvät ovat yleensä hitaampia
          kalliimpia, mutta puhelinoperaattorit tarjoavat säännöllisesti myös
          hyviä liittymätarjouksia. Siitä huolimatta voidaan sanoa että se
          halvin mobiililaajakaista on säännöllisesti myös se hitain
          mobiililaajakaista. Osaan liittymistä kuuluu lisäksi puhelut ja
          tekstiviestit, joten niitä voidaan käyttää monipuolisesti.
        </TextArea>
      </Container>
    </GlobalStyles>
  )
}

export const query = graphql`
  query {
    allInternetPlansJson(sort: { fields: price }) {
      nodes {
        benefits
        id
        img
        internetSpeed
        internetType
        name
        openFee
        price
        url
      }
    }
  }
`

export default FrontPage
